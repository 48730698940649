import React, { ReactNode } from 'react';
import { clsx } from 'clsx';

type Props = {
  type?: 'info' | 'error';
  children: ReactNode;
};

/**
 * Renders alert message on the screen.
 */
export const Alert = ({ type = 'info', children }: Props) => {
  const classNames = clsx('alert', type);
  return <div className={classNames}>{children}</div>;
};
