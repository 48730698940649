const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Helper to generate random identifiers.
 */
export const generateId = () => {
  let id = '';
  for (let i = 0; i < 32; i++) {
    id += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS.length));
  }
  return id;
};
