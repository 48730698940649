import { AllPostsEntry } from '../../packages/blog-data/types/AllPostsEntry.js';

export type TagInfo = {
  tag: string;
  count: number;
};

/**
 * Extracts tags info on frontend from posts data.
 */
export const extractTagsInfo = (posts: AllPostsEntry[]) => {
  const tagCounts = new Map<string, number>();
  for (const post of posts) {
    for (const tag of post.tags) {
      const currentCount = tagCounts.get(tag);
      if (currentCount === undefined) {
        tagCounts.set(tag, 1);
      } else {
        tagCounts.set(tag, currentCount + 1);
      }
    }
  }
  return Array.from(tagCounts.entries())
    .map((entry) => ({ tag: entry[0], count: entry[1] } as TagInfo))
    .sort(compareByCount);
};

/**
 * Helper to sort tags info by post count.
 */
const compareByCount = (t1: TagInfo, t2: TagInfo) => {
  const c1 = t1.count;
  const c2 = t2.count;
  return c1 === c2 ? 0 : c1 > c2 ? -1 : 1;
};
