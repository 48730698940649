import React from 'react';
import { useMemo } from 'react';
import { useSelectedTag } from './TagSelection.js';
import { Summary } from '../../rlaanemets-shared/components/Summary.js';
import { AllPostsEntry } from '../../packages/blog-data/types/AllPostsEntry.js';

type Props = {
  posts: AllPostsEntry[];
};

/**
 * Helper component to filter and render posts by the selected tag.
 */
export const FilteredPosts = ({ posts }: Props) => {
  const selectedTag = useSelectedTag();

  const filteredPosts = useMemo(() => {
    if (selectedTag === undefined) {
      return posts;
    }
    return posts.filter((post) => post.tags.includes(selectedTag));
  }, [posts, selectedTag]);

  return (
    <>
      {filteredPosts.map((post) => (
        <Summary
          key={post.slug}
          slug={post.slug}
          title={post.title}
          excerpt={post.excerpt}
          publishDate={post.publishDate}
          tags={post.tags}
        />
      ))}
    </>
  );
};
