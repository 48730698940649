/**
 * Creates grid-template-columns value for the given
 * number of the equal-width columns.
 */
export const createGridTemplateColumns = (columns: number) => {
  const template = [];
  for (let i = 0; i < columns; i++) {
    template.push('1fr');
  }
  return template.join(' ');
};
