import { PixelParameters } from '../../model/analytics/PixelParameters.js';
import { getSessionId } from './getSession.js';
import { getUserId } from './getUserId.js';

// TODO: move to common library and add to Blog-Core.

/**
 * Injects the tracking pixel into the page body.
 */
export const createTrackingPixel = () => {
  if (window.navigator.userAgent.match(/bot|crawler|spider/i)) {
    // Skips GoogleBot etc.
    return;
  }

  const params: PixelParameters = {
    u: getUserId(),
    s: getSessionId(),
    t: document.title,
    p: window.location.pathname
  };

  if (params.u === 'raivo') {
    console.log('Skipping analytics for raivo.');
    return;
  }

  const image = document.createElement('img');
  image.width = 1;
  image.height = 1;
  image.style.position = 'fixed';
  image.style.bottom = '0';
  image.style.right = '0';
  image.alt = 'Reader information sent to the backend';

  const query = Object.entries(params)
    .map((entry) => `${entry[0]}=${encodeURIComponent(entry[1])}`)
    .join('&');
  image.src = `/images/reader.png?${query}`;

  // Injects the tracking image into the document.
  document.body.appendChild(image);
};
