import { useCallback, MouseEvent } from 'react';

/**
 * Creates MouseEvent wrapper handler that automatically
 * calls stopPropagate on the event.
 */
export const useStopPropagate = (handler: () => void) => {
  return useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      handler();
    },
    [handler]
  );
};
