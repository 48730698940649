import React from 'react';
import { CommentPublicData } from '../../packages/comments/CommentPublicData.js';

type Props = {
  comment: CommentPublicData;
};

/**
 * Renders site link for a comment.
 */
export const SiteLink = ({ comment }: Props) => {
  return (
    <a href={comment.site} target="_blank" rel="noreferrer nofollow">
      {comment.author}
    </a>
  );
};
