/**
 * Trims all strings in the given object. Used for cleaning
 * up inputs before validation and submitting.
 */
export const trimStrings = (value: unknown): unknown => {
  if (Array.isArray(value)) {
    return value.map((item) => trimStrings(item));
  } else if (isObject(value) && Object.getPrototypeOf(value) !== Object.prototype) {
    // Non-simple object, for example, a File instance.
    return value;
  } else if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value as object).map(([key, value]) => [key, trimStrings(value)])
    );
  } else if (typeof value === 'string') {
    return value.trim();
  } else {
    return value;
  }
};

const isObject = (value: unknown) => typeof value === 'object' && value !== null;
