import React from 'react';
import { useLightboxContext } from './LightboxProvider.js';
import { useStopPropagate } from './useStopPropagate.js';
import { RightArrow } from './RightArrow.js';
import { LeftArrow } from './LeftArrow.js';
import { CloseMark } from './CloseMark.js';

/**
 * Renders the lightbox controls.
 */
export const LightboxControls = () => {
  const { goToPrevious, goToNext, handleClose } = useLightboxContext();

  const handleNextClick = useStopPropagate(goToNext);
  const handlePreviousClick = useStopPropagate(goToPrevious);
  const handleCloseClick = useStopPropagate(handleClose);

  return (
    <div className="controls">
      <div className="control" onClick={handlePreviousClick} title="Previous image">
        <LeftArrow />
      </div>
      <div className="control" onClick={handleNextClick} title="Next image">
        <RightArrow />
      </div>
      <div className="control" onClick={handleCloseClick} title="Close lightbox">
        <CloseMark />
      </div>
    </div>
  );
};
