import { useRef } from 'react';
import * as uuid from 'uuid';

/**
 * Helper to generate ID-s for UI purpose. Uses UUID v4.
 */
export const useId = () => {
  const ref = useRef<string>();
  if (ref.current === undefined) {
    ref.current = uuid.v4();
  }
  return ref.current;
};
