import React from 'react';

/**
 * Renders link to the privacy policy.
 */
export const PrivacyPolicyLink = () => {
  return (
    <a href="/privacy-policy" target="_blank">
      privacy policy
    </a>
  );
};
