import { ChangeEvent, useCallback } from 'react';

/**
 * Helper hook to extract event.target.value in change handlers.
 */
export const useTargetValue = (onChange?: (value: string) => void) => {
  return useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );
};
