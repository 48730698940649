import React from 'react';
import { SiteLink } from './SiteLink.js';
import { CommentPublicData } from '../../packages/comments/CommentPublicData.js';

type Props = {
  comment: CommentPublicData;
};

/**
 * Renders author in comment meta section.
 */
export const Author = ({ comment }: Props) => {
  if (comment.site === undefined) {
    return <>{comment.author}</>;
  }
  return <SiteLink comment={comment} />;
};
