import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { euc } from '../../packages/url/encodeUriComponents.js';

type TagSelectionState = {
  selectedTag?: string;
  setSelectedTag: (tag?: string) => void;
};

const TagSelectionContext = createContext<TagSelectionState>({} as TagSelectionState);

type Props = {
  children: ReactNode;
};

/**
 * Provider for the tag selection context.
 */
export const TagSelectionProvider = ({ children }: Props) => {
  const [tag, setTag] = useState<string>();

  const setSelectedTag = useCallback((tag?: string) => {
    setTag(tag);
    if (tag === undefined) {
      history.pushState(null, '', euc`/all`);
    } else {
      history.pushState(null, '', euc`/all?t=${tag}`);
    }
  }, []);

  const handleCurrentLocation = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    setTag(params.get('t') ?? undefined);
  }, []);

  const handlePopstate = useCallback(() => {
    handleCurrentLocation();
  }, [handleCurrentLocation]);

  useEffect(() => {
    handleCurrentLocation();
  }, [handleCurrentLocation]);

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [handlePopstate]);

  const value = { selectedTag: tag, setSelectedTag };

  return <TagSelectionContext.Provider value={value}>{children}</TagSelectionContext.Provider>;
};

/**
 * Helper hook to use currently selected tag.
 */
export const useSelectedTag = () => useContext(TagSelectionContext).selectedTag;

/**
 * Helper hook to access function to set currently selected tag.
 */
export const useSetSelectedTag = () => useContext(TagSelectionContext).setSelectedTag;
