import React from 'react';

// https://www.svgrepo.com/svg/522366/chevron-left
// https://www.svgrepo.com/author/Luka%20Marr/

/**
 * Renders the left arrow SVG icon.
 */
export const LeftArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 0 24 24" width="36" height="36" strokeWidth="1.5">
      <path
        d="M14.5 17L9.5 12L14.5 7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
