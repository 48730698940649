import React, { Fragment } from 'react';
import { euc } from '../../packages/url/encodeUriComponents.js';

type Props = {
  tags: string[];
};

/**
 * Renders the list of tags.
 */
export const Tags = ({ tags }: Props) => {
  return (
    <div className="tags">
      {tags.map((tag, i) => (
        <Fragment key={tag}>
          {i > 0 && <> </>}
          <a href={euc`/all?t=${tag}`} className="tag">
            {tag}
          </a>
        </Fragment>
      ))}
    </div>
  );
};
