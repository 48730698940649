import React from 'react';
import { Tags } from './Tags.js';

type Props = {
  publishDate: string;
  tags: string[];
};

/**
 * Renders post metainfo either in summary or post page.
 */
export const Meta = ({ publishDate, tags }: Props) => {
  return (
    <div className="meta">
      <div>On {publishDate}</div>
      <Tags tags={tags} />
    </div>
  );
};
