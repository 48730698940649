import { clsx } from 'clsx';
import React, { MouseEvent, useCallback } from 'react';
import { TagInfo } from './extractTagsInfo.js';
import { useSelectedTag, useSetSelectedTag } from './TagSelection.js';
import { euc } from '../../packages/url/encodeUriComponents.js';

type Props = {
  tagInfo: TagInfo;
};

/**
 * Renders one tag in the tag cloud.
 */
export const CloudTag = ({ tagInfo }: Props) => {
  const selectedTag = useSelectedTag();
  const setSelectedTag = useSetSelectedTag();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setSelectedTag(tagInfo.tag);
    },
    [setSelectedTag, tagInfo]
  );

  return (
    <a
      href={euc`/all?t=${tagInfo.tag}`}
      className={clsx('tag', { 'active': tagInfo.tag === selectedTag })}
      onClick={handleClick}
    >
      {tagInfo.tag} ({tagInfo.count})
    </a>
  );
};
