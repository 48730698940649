import { FormEvent, useCallback, useState } from 'react';
import { trimStrings } from './trimStrings.js';
import { hasError } from './hasError.js';
import { Configuration } from './Configuration.js';
import { FormHook } from './FormHook.js';

/**
 * Top-level form helper hook. Ties together validation
 * and submission.
 */
export const useForm = <T extends object, E extends object>(
  configuration: Configuration<T, E>
): FormHook<T, E> => {
  // Extracting the configuration values.
  const { initialValues, initialErrors, validate, submit, handleSubmissionError, handleValidationError } =
    configuration;

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);

  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);

  // Wraps and runs the validation process. Returns
  // boolean whether the values are valid.
  const doValidate = useCallback(
    async (values: T) => {
      if (typeof validate !== 'function') {
        // Skips validation when the validation function is not set.
        return true;
      }
      try {
        const errors = await validate(values);
        if (hasError(errors)) {
          setErrors(errors);
          return false;
        }
        return true;
      } catch (err) {
        if (typeof handleValidationError === 'function') {
          setErrors(handleValidationError(err));
          return false;
        } else {
          throw err;
        }
      }
    },
    [validate, handleValidationError]
  );

  const reset = useCallback(() => {
    setValues(initialValues);
  }, [initialValues]);

  const doSubmit = useCallback(
    async (values: T) => {
      try {
        await submit(values, reset);
        return true;
      } catch (err) {
        if (typeof handleSubmissionError === 'function') {
          // Form is configured to handle submission errors itself.
          setErrors(handleSubmissionError(err));
          return false;
        } else {
          throw err;
        }
      }
    },
    [submit, handleSubmissionError, reset]
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      setErrors(initialErrors);
      setLoading(true);
      setSuccess(false);
      setValid(true);

      // Automatically trims the values.
      const trimmedState = trimStrings(values) as T;

      try {
        const passesValidation = await doValidate(trimmedState);
        setValid(passesValidation);

        if (passesValidation) {
          setSuccess(await doSubmit(trimmedState));
        }
      } finally {
        setLoading(false);
      }
    },
    [values, setErrors, doSubmit, doValidate, initialErrors]
  );

  return {
    values,
    errors,
    setValues,
    reset,
    handleSubmit,
    isSuccess,
    isLoading,
    isValid
  };
};
