import React, { useCallback, useState } from 'react';
import { Question, getRandomQuestion } from './Questions.js';
import { PrivacyPolicyLink } from './PrivacyPolicyLink.js';
import { CommentCreateData } from '../../packages/comments/CommentCreateData.js';
import { requestPostJson } from '../../packages/fetcher/fetcherFunctions.js';
import { useForm } from '../../packages/form/useForm.js';
import { createWriteProperty } from '../../packages/optics/optics/property/createWriteProperty.js';
import { euc } from '../../packages/url/encodeUriComponents.js';
import { Form } from '../../packages/ui/form/Form.js';
import { FormRow } from '../../packages/ui/form/FormRow.js';
import { Alert } from '../../packages/ui/Alert.js';
import { Input } from '../../packages/ui/form/Input.js';
import { Textarea } from '../../packages/ui/form/Textarea.js';
import { Buttons } from '../../packages/ui/form/Buttons.js';
import { Button } from '../../packages/ui/button/Button.js';

type Values = {
  author: string;
  comment: string;
  answer: string;
  site: string;
};

const initialValues: Values = {
  author: '',
  comment: '',
  answer: '',
  site: ''
};

type Errors = {
  author?: string;
  comment?: string;
  answer?: string;
  site?: string;
  generic?: string;
};

type Props = {
  postId: string;
  slug: string;
  title: string;
  updateComments: () => Promise<void>;
};

/**
 * Renders form for entering new comments.
 */
export const CommentForm = ({ postId, slug, title, updateComments }: Props) => {
  const [question] = useState(() => getRandomQuestion());

  const validate = useCallback(
    async (values: Values) => {
      return validateInputs(question, values);
    },
    [question]
  );

  const submit = useCallback(
    async (values: Values, reset: () => void) => {
      const url = euc`/api/post/${postId}/comments`;
      await requestPostJson<string, CommentCreateData>(url, {
        author: values.author,
        content: values.comment,
        site: values.site === '' ? undefined : values.site,
        slug,
        title
      });
      reset();
      await updateComments();
    },
    [slug, title, postId, updateComments]
  );

  const handleSubmissionError = useCallback((err: unknown): Errors => {
    return { generic: err + '' };
  }, []);

  const { handleSubmit, values, setValues, errors, isSuccess } = useForm<Values, Errors>({
    initialValues,
    initialErrors: {},
    submit,
    validate,
    handleSubmissionError
  });

  const handleAuthorChange = createWriteProperty(setValues, 'author');
  const handleCommentChange = createWriteProperty(setValues, 'comment');
  const handleAnswerChange = createWriteProperty(setValues, 'answer');

  return (
    <Form onSubmit={handleSubmit}>
      {isSuccess && (
        <FormRow columns={1}>
          <Alert type="info">Your comment was added!</Alert>
        </FormRow>
      )}
      {errors.generic && (
        <FormRow columns={1}>
          <Alert type="error">
            {errors.generic}
            <br />
            Please report errors to raivo@infdot.com
          </Alert>
        </FormRow>
      )}
      <FormRow columns={1}>
        <Input label="Author" value={values.author} onChange={handleAuthorChange} error={errors.author} />
      </FormRow>
      <FormRow columns={1}>
        <small>
          You can use links inside the comment text. Make sure there are no spaces inside the link and the
          link is shorter than 110 characters. The links will <strong>not</strong> be rendered clickable. You
          can use empty lines to break the text into paragraphs. Any other type of formatting is not
          supported.
        </small>
      </FormRow>
      <FormRow columns={1}>
        <Textarea
          label="Comment"
          value={values.comment}
          onChange={handleCommentChange}
          error={errors.comment}
          rows={5}
        />
      </FormRow>
      <FormRow columns={1}>
        <div>{question.question}</div>
      </FormRow>
      <FormRow columns={1}>
        <Input label="Answer" value={values.answer} onChange={handleAnswerChange} error={errors.answer} />
      </FormRow>
      <FormRow columns={1}>
        By commenting you are agreeing to the <PrivacyPolicyLink />.
      </FormRow>
      <FormRow columns={1}>
        <Buttons>
          <Button type="submit">Post</Button>
        </Buttons>
      </FormRow>
    </Form>
  );
};

/**
 * Validates the comment form inputs.
 */
const validateInputs = (question: Question, values: Values): Errors => {
  const errors: Errors = {};
  if (values.author === '') {
    errors.author = 'Please enter your name.';
  }
  if (values.comment === '') {
    errors.comment = 'Please enter your comment.';
  }
  if (values.answer === '') {
    errors.answer = 'Please enter the question answer.';
  } else if (values.answer !== question.answer) {
    errors.answer = 'Wrong answer to the question.';
  }
  return errors;
};
