import { useLayoutEffect } from 'react';

/**
 * Helper hook to register events on the "window" object.
 */
export const useWindowEvent = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any
) => {
  useLayoutEffect(() => {
    window.addEventListener(type, listener);
    return () => {
      window.removeEventListener(type, listener);
    };
  }, [type, listener]);
};
