export type Hashinfo = {
  lightboxVisible: boolean;
  selectedIndex: number;
};

/**
 * Extracts information from the current location hash
 * whether the lightbox is visible and which image should be
 * selected by default.
 */
export const extractHashinfo = (): Hashinfo => {
  const hash = window.location.hash;
  const matchWithIndex = hash.match(/^#lightbox\/(\d+)$/);
  if (matchWithIndex) {
    return { lightboxVisible: true, selectedIndex: parseInt(matchWithIndex[1], 10) };
  }
  const matchNormal = hash.match(/^#lightbox$/);
  if (matchNormal) {
    return { lightboxVisible: true, selectedIndex: 0 };
  }
  return { lightboxVisible: false, selectedIndex: 0 };
};
