import React from 'react';
import { useLightboxContext } from './LightboxProvider.js';

/**
 * Renders the lightbox photo loading indicator.
 */
export const LightboxSpinner = () => {
  const { isLoading } = useLightboxContext();

  return <>{isLoading && <div className="spinner">Loading ...</div>}</>;
};
