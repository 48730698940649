import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Position } from './Position.js';
import { Orientation, getOrientation } from './getOrientation.js';
import { calculateDistance } from './calculateDistance.js';
import { clamp } from './clamp.js';
import { useLightboxContext } from './LightboxProvider.js';
import { useWindowEvent } from './useWindowEvent.js';

const MIN_SCALE = 0.2;
const MAX_SCALE = 4;

type CachedPointer = {
  pointerId: number;
  /**
   * Latest value of the pointer X coordinate.
   */
  screenX: number;
  /**
   * Latest value of the pointer Y coordinate.
   */
  screenY: number;
  /**
   * First value of the pointer X coordinate.
   */
  originalScreenX: number;
  /**
   * First value of the pointer Y coordinate.
   */
  originalScreenY: number;
  /**
   * Scale value when the pointer was added.
   */
  scale: number;
};

/**
 * Renders the lightbox image section.
 */
export const LightboxImage = () => {
  const { markLoaded, photo, selectedIndex } = useLightboxContext();

  const [translate, setTranslate] = useState<Position>(() => ({ x: 0, y: 0 }));
  const [scale, setScale] = useState(1);

  const minimumScaleHint = useRef(MIN_SCALE);
  const orientationState = useRef<Orientation>(getOrientation());

  const lightboxRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  // Helper cache to detect multi-pointer gestures.
  const pointerCache = useRef<CachedPointer[]>();

  /**
   * Resets the scale and panning position. Should be called after
   * loading the image and after rotating the screen.
   */
  const resetView = useCallback(() => {
    const image = imgRef.current;
    const lightbox = lightboxRef.current;
    if (image && lightbox) {
      let x = 0;
      let y = 0;
      // Center the image on lightbox.
      x = Math.round((lightbox.clientWidth - image.width) / 2);
      y = Math.round((lightbox.clientHeight - image.height) / 2);
      // Scales image to fill the lightbox area.
      const widthScale = lightbox.clientWidth / image.width;
      const heightScale = lightbox.clientHeight / image.height;
      const scale = clamp(Math.max(widthScale, heightScale), MIN_SCALE, MAX_SCALE);
      // Set smaller value as the minimum scale hint.
      // Allows to display the whole image.
      minimumScaleHint.current = clamp(Math.min(widthScale, heightScale), MIN_SCALE, MAX_SCALE);
      setScale(scale);
      setTranslate({ y, x });
      // Clears up the events cache.
      pointerCache.current = [];
    }
  }, []);

  /**
   * Handles case when a pointer device is pressed down on the screen.
   */
  const handlePointerDown = useCallback(
    (event: PointerEvent) => {
      if (!pointerCache.current) {
        pointerCache.current = [];
      }
      if (pointerCache.current.length < 2) {
        // We only handle gestures up to size 2.
        pointerCache.current.push({
          pointerId: event.pointerId,
          screenX: event.screenX,
          screenY: event.screenY,
          originalScreenX: event.screenX,
          originalScreenY: event.screenY,
          scale
        });
        if (pointerCache.current && pointerCache.current.length === 2) {
          // Second pointer was added.
          // Current gesture is upgraded to "zoom".
          const cachedPointer1 = pointerCache.current[0];
          cachedPointer1.originalScreenX = cachedPointer1.screenX;
          cachedPointer1.originalScreenY = cachedPointer1.screenY;
          cachedPointer1.scale = scale;
        }
      }
    },
    [scale]
  );

  /**
   * Handles pointer moves (dragging, pinch zoom, etc.).
   */
  const handlePointerMove = useCallback((event: PointerEvent) => {
    if (pointerCache.current) {
      if (pointerCache.current.length === 1) {
        const cachedPointer = pointerCache.current[0];
        const diffX = event.screenX - cachedPointer.screenX;
        const diffY = event.screenY - cachedPointer.screenY;
        // Sets the image location.
        setTranslate(({ x, y }) => ({ x: x + diffX, y: y + diffY }));
        // Updates the cached value.
        cachedPointer.screenX = event.screenX;
        cachedPointer.screenY = event.screenY;
      } else if (pointerCache.current.length === 2) {
        // Updates the matching cached value.
        const cacheIndex = pointerCache.current.findIndex((pointer) => pointer.pointerId === event.pointerId);
        if (cacheIndex >= 0 && cacheIndex < pointerCache.current.length) {
          pointerCache.current[cacheIndex].screenX = event.screenX;
          pointerCache.current[cacheIndex].screenY = event.screenY;
        }
        const cachedPointer1 = pointerCache.current[0];
        const cachedPointer2 = pointerCache.current[1];
        // Distance when pinch zoom started.
        const startDistance = calculateDistance(
          cachedPointer1.originalScreenX,
          cachedPointer1.originalScreenY,
          cachedPointer2.originalScreenX,
          cachedPointer2.originalScreenY
        );
        const endDistance = calculateDistance(
          cachedPointer1.screenX,
          cachedPointer1.screenY,
          cachedPointer2.screenX,
          cachedPointer2.screenY
        );
        const distanceFactor = endDistance / startDistance;
        setScale(clamp(cachedPointer2.scale * distanceFactor, minimumScaleHint.current, MAX_SCALE));
      }
    }
  }, []);

  /**
   * Handles pointer leaving the screen surface.
   */
  const handlePointerUp = useCallback(() => {
    if (pointerCache.current) {
      if (pointerCache.current.length === 1) {
        const cachedPointer = pointerCache.current[0];
        const hasUpdatedCoordinates =
          cachedPointer.originalScreenX !== cachedPointer.screenX ||
          cachedPointer.originalScreenY !== cachedPointer.screenY;
        if (!hasUpdatedCoordinates) {
          // Just click or tap. Re-centers the image.
          resetView();
        }
      }
    }
    // Empties the events cache.
    pointerCache.current = [];
  }, [resetView]);

  /**
   * Handles pointer cancel events.
   */
  const handlePointerCancel = useCallback(() => {
    // Empties the events cache.
    pointerCache.current = [];
  }, []);

  /**
   * Zooming with mouse wheel.
   */
  const handleWheel = useCallback(
    (event: WheelEvent) => {
      const isZoomIn = event.deltaY < 0;
      if (isZoomIn) {
        setScale(clamp(scale * 1.1, minimumScaleHint.current, MAX_SCALE));
      } else {
        setScale(clamp(scale * (1 / 1.1), minimumScaleHint.current, MAX_SCALE));
      }
    },
    [scale]
  );

  /**
   * Called when the image has been changed and loaded.
   */
  const handleLoaded = useCallback(() => {
    resetView();
  }, [resetView]);

  /**
   * Handles screen resize. Recalculates the image position
   * only when the orientation changes.
   */
  const handleResize = useCallback(() => {
    const orientation = getOrientation();
    if (orientation !== orientationState.current) {
      resetView();
      orientationState.current = orientation;
    }
  }, [resetView]);

  // Registers the event handlers.
  useWindowEvent('pointerdown', handlePointerDown);
  useWindowEvent('pointermove', handlePointerMove);
  useWindowEvent('pointerup', handlePointerUp);
  useWindowEvent('pointercancel', handlePointerCancel);
  useWindowEvent('wheel', handleWheel);
  useWindowEvent('resize', handleResize);

  useEffect(() => {
    const interval = setInterval(() => {
      const image = imgRef.current;
      markLoaded(image ? image.complete : false);
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, [markLoaded, selectedIndex]);

  return (
    <div ref={lightboxRef} className="image">
      <img
        ref={imgRef}
        src={photo.full}
        alt={photo.caption}
        onLoad={handleLoaded}
        style={{ transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})` }}
      />
    </div>
  );
};
