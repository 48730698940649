/**
 * Question with answer used for detecting bots.
 */
export type Question = {
  question: string;
  answer: string;
};

/**
 * Returns a random question.
 */
export const getRandomQuestion = () => questions[Math.floor(questions.length * Math.random())];

/**
 * Sets of questions with answers for detecting bots.
 */
export const questions: Question[] = [
  {
    question: 'What is 2-nd digit in 03456',
    answer: '3'
  },
  {
    question: 'What is 3-rd digit in 03456',
    answer: '4'
  },
  {
    question: 'What is 4-th digit in 03456',
    answer: '5'
  },
  {
    question: 'Earth, Mercury and Venus are ...',
    answer: 'planets'
  },
  {
    question: 'Is water wet (yes/no)?',
    answer: 'yes'
  }
];
