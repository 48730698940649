import React from 'react';
import { useLightboxContext } from './LightboxProvider.js';

/**
 * Renders the lightbox title.
 */
export const LightboxTitle = () => {
  const { postTitle } = useLightboxContext();

  return <div className="title">{postTitle}</div>;
};
