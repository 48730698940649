/**
 * Extracts JSend response data from the fetch JSON response.
 */
export const extractJSendResponse = (object: unknown) => {
  if (typeof object !== 'object' || object === null) {
    throw new Error('Fetch response is not an object.');
  }
  const status = (object as any).status;
  if (status === 'success') {
    return (object as any).data;
  } else if (status === 'error') {
    const message = (object as any).message || 'Unknown error.';
    const code = (object as any).code;
    const error = new Error(message);
    (error as any).code = code;
    throw error;
  } else {
    throw new Error(`Invalid response status: ${status}.`);
  }
};
