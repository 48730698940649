import React from 'react';

// https://www.svgrepo.com/svg/522388/close
// https://www.svgrepo.com/author/Luka%20Marr/

/**
 * Renders the close button SVG icon.
 */
export const CloseMark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="1.5">
        <path d="M7 17L16.8995 7.10051" />
        <path d="M7 7.00001L16.8995 16.8995" />
      </g>
    </svg>
  );
};
