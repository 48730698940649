import { clsx } from 'clsx';
import React, { memo } from 'react';
import { InputWrapper } from './InputWrapper.js';
import { useTargetValue } from './useTargetValue.js';

type Props = {
  id?: string;
  label?: string;
  value: string;
  rows?: number;
  placeholder?: string;
  onChange: (value: string) => void;
  error?: string;
};

/**
 * Renders the <textarea> element.
 */
export const Textarea = memo(function Textarea({
  id,
  label,
  value,
  rows,
  placeholder,
  onChange,
  error
}: Props) {
  const labelClass = clsx({ 'error': error });
  const inputClass = clsx({ 'error': error });
  const helpClass = clsx('help', { 'error': error });

  const handleChange = useTargetValue(onChange);

  return (
    <InputWrapper>
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        value={value}
        rows={rows}
        placeholder={placeholder}
        className={inputClass}
        onChange={handleChange}
      />
      {error && <div className={helpClass}>{error}</div>}
    </InputWrapper>
  );
});
