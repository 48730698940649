import { Photo } from './Photo.js';

/**
 * Extracts photos from page (if there are any).
 */
export const extractPhotos = (): Photo[] => {
  const divs = Array.from(document.querySelectorAll('div'));
  const photos = divs.filter(
    (div) => div.classList.contains('photo') || div.classList.contains('photo-item')
  );

  // Photos without anchor element have no large (Lightbox) variants.
  return photos
    .filter((photo) => photo.querySelector('a') !== null)
    .map((photo) => {
      const linkElement = photo.querySelector('a');
      if (!linkElement) {
        throw new Error('Photo has no anchor element.');
      }
      const imageElement = photo.querySelector('img');
      if (!imageElement) {
        throw new Error('Photo has no image element.');
      }
      const small = imageElement.src;
      const full = linkElement.href;
      return { small, full, caption: extractCaption(photo), element: photo };
    });
};

/**
 * Extracts possible caption from the photo element.
 */
const extractCaption = (photoElement: Element): string | undefined => {
  if (photoElement.classList.contains('photo')) {
    // Only large photos have caption.
    const captionElement = photoElement.querySelector('.caption');
    if (captionElement) {
      const captionText = captionElement.textContent;
      if (!captionText) {
        throw new Error('Caption text is null.');
      }
      return captionText.trim();
    }
  }
};
