import { PostData } from '../../packages/blog-data/types/PostData.js';
import { POST_DATA_ELEMENT_ID } from '../../rlaanemets-shared/constants.js';

/**
 * Extracts metadata of the current entry.
 */
export const extractPostData = () => {
  const postDataElement = document.getElementById(POST_DATA_ELEMENT_ID);
  if (!postDataElement) {
    return;
  }
  const postDataJson = postDataElement.textContent;
  if (!postDataJson) {
    return;
  }
  return JSON.parse(postDataJson) as PostData;
};
