/**
 * Formats raw text comment to an array of paragraphs.
 */
export const formatComment = (content: string): string[] => {
  const normalized = normalizeLineEnds(content);

  return normalized
    .split('\n\n')
    .map((paragraph) => paragraph.trim())
    .filter((paragraph) => paragraph !== '');
};

/**
 * Normalizes comment line ends.
 */
export const normalizeLineEnds = (value: string) => value.replace(/\n\r?/g, '\n').replace(/\n\n+/g, '\n\n');
