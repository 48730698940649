/**
 * Makes off-site links to open in new window/tab.
 */
export const rewriteExternalLinks = () => {
  const hostname = window.location.hostname;
  const navlink = document.querySelector('nav > a');

  const check = navlink ? (navlink as HTMLAnchorElement).hostname : undefined;

  if (typeof hostname === 'string' && hostname === check) {
    const links = document.querySelectorAll('a');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (typeof link.hostname === 'string' && link.hostname !== hostname) {
        link.target = '_blank';
      }
    }
  }
};
