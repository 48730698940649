/**
 * Helper tag function for template literals to automatically encode
 * URL parameters in URL strings. Name euc means "encode uri components".
 */
export const euc = (strings: TemplateStringsArray, ...params: (string | number)[]) => {
  let ret = '';
  strings.forEach((string, i) => {
    if (i < params.length) {
      ret += string + encodeURIComponent(params[i]);
    } else {
      ret += string;
    }
  });
  return ret;
};
