import React, { FormEvent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onSubmit?: (event: FormEvent) => void;
  gap?: 'normal' | 'small';
};

/**
 * Renders <form> element.
 */
export const Form = ({ children, onSubmit, gap = 'normal' }: Props) => {
  const flexGap = gap === 'normal' ? '1rem' : '0.5rem';
  return (
    <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', gap: flexGap }}>
      {children}
    </form>
  );
};
