/**
 * Element ID of the embedded post data.
 */
export const POST_DATA_ELEMENT_ID = 'post-data';

/**
 * ID of the comments holder element.
 */
export const COMMENTS_ELEMENT_ID = 'comments';

/**
 * ID of the lightbox wrapper element.
 */
export const LIGHTBOX_ELEMENT_ID = 'lightbox';

/**
 * ID of the All posts container element.
 */
export const ALL_POSTS_ELEMENT_ID = 'all-posts';

/**
 * ID of the element that contains embedded all posts data.
 */
export const ALL_POSTS_DATA_ELEMENT_ID = 'posts-data';
