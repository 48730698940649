import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import prolog from 'highlight.js/lib/languages/prolog';
import css from 'highlight.js/lib/languages/css';
import cpp from 'highlight.js/lib/languages/cpp';
import bash from 'highlight.js/lib/languages/bash';
import diff from 'highlight.js/lib/languages/diff';
import makefile from 'highlight.js/lib/languages/makefile';
import json from 'highlight.js/lib/languages/json';
import ini from 'highlight.js/lib/languages/ini';
import nginx from 'highlight.js/lib/languages/nginx';
import sql from 'highlight.js/lib/languages/sql';
import typescript from 'highlight.js/lib/languages/typescript';

hljs.registerLanguage('js', javascript);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('prolog', prolog);
hljs.registerLanguage('css', css);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('diff', diff);
hljs.registerLanguage('makefile', makefile);
hljs.registerLanguage('json', json);
hljs.registerLanguage('ini', ini);
hljs.registerLanguage('nginx', nginx);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('ts', typescript);
hljs.registerLanguage('tsx', typescript);

const highlightAll = () => {
  const snippets = document.querySelectorAll('pre code');
  for (let i = 0; i < snippets.length; i++) {
    const snippet = snippets.item(i);
    const classes = Array.from(snippet.classList);
    for (const cls of classes) {
      const match = cls.match(/^language-(.+)$/);
      if (match) {
        const language = match[1];
        if (snippet instanceof HTMLElement) {
          snippet.classList.add(language);
          hljs.highlightElement(snippet);
        }
      }
    }
  }
};

/**
 * Sets up highlight.js and installs window "load"
 * handler to highlight all code snippets.
 */
export const initializeHighlight = () => {
  window.addEventListener('load', highlightAll);
};
