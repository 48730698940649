import React from 'react';
import { createRoot } from 'react-dom/client';
import { Posts } from './Posts.js';
import { ALL_POSTS_DATA_ELEMENT_ID, ALL_POSTS_ELEMENT_ID } from '../../rlaanemets-shared/constants.js';
import { AllPostsEntry } from '../../packages/blog-data/types/AllPostsEntry.js';

/**
 * Renders all posts page.
 */
export const renderPosts = () => {
  const allPostsElement = document.getElementById(ALL_POSTS_ELEMENT_ID);
  if (!allPostsElement) {
    return;
  }
  const postsJsonElement = document.getElementById(ALL_POSTS_DATA_ELEMENT_ID);
  if (!postsJsonElement) {
    return;
  }
  const posts = JSON.parse(postsJsonElement.textContent as string) as AllPostsEntry[];
  createRoot(allPostsElement).render(<Posts posts={posts} />);
};
