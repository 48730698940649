import { Update } from '../Update.js';
import { Write } from '../Write.js';
import { FunctionCache } from '../cache.js';
import { createModifyProperty } from './createModifyProperty.js';

const cache = new FunctionCache();

/**
 * Creates "write" function for the given object property.
 */
export const createWriteProperty = <T extends object, K extends keyof T>(
  update: Update<T>,
  key: K
): Write<T[K]> =>
  cache.cached(update, key, () => (value: T[K]) => update(createModifyProperty<T, K>(() => value, key)));
