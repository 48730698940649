import React from 'react';
import { Meta } from './Meta.js';

type Props = {
  slug: string;
  title: string;
  excerpt: string;
  publishDate: string;
  tags: string[];
};

/**
 * Renders one post summary.
 */
export const Summary = ({ slug, title, excerpt, publishDate, tags }: Props) => {
  const link = `/${slug}`;

  return (
    <div className="summary">
      <h3>
        <a href={link}>{title}</a>
      </h3>
      <p>
        {excerpt}...
        <br />
        <a href={link}>read more</a>
      </p>
      <Meta publishDate={publishDate} tags={tags} />
    </div>
  );
};
