import { generateId } from './generateId.js';

/**
 * Reads the previous session ID or generates a new one.
 */
export const getSessionId = () => {
  const match = document.cookie.match(/readers_session\s*=\s*([a-z0-9-]+)/);
  if (match) {
    return match[1];
  }
  const id = generateId();
  document.cookie = 'readers_session=' + id + '; path=/';
  return id;
};
