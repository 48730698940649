import React from 'react';

// https://www.svgrepo.com/svg/522374/chevron-right
// https://www.svgrepo.com/author/Luka%20Marr/

/**
 * Renders the right arrow SVG icon.
 */
export const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 24 24" width="36" height="36" strokeWidth="1.5">
      <path
        d="M9.5 7L14.5 12L9.5 17"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
