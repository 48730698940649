import React, { useCallback, useEffect, useState } from 'react';
import { Meta } from './Meta.js';
import { CommentForm } from './CommentForm.js';
import { CommentPublicData } from '../../packages/comments/CommentPublicData.js';
import { formatComment } from '../../packages/comments/formatComment.js';
import { requestGetJson } from '../../packages/fetcher/fetcherFunctions.js';
import { euc } from '../../packages/url/encodeUriComponents.js';

type Props = {
  postId: string;
  slug: string;
  title: string;
};

/**
 * Renders the comments list.
 */
export const Comments = ({ postId, slug, title }: Props) => {
  const [comments, setComments] = useState<CommentPublicData[]>([]);
  const [error, setError] = useState<unknown>();

  const [isLoading, setLoading] = useState(false);

  const updateComments = useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      const url = euc`/api/post/${postId}/public-comments`;
      const comments = await requestGetJson<CommentPublicData[]>(url);
      setComments(comments.filter((comment) => !comment.content.startsWith('__test')));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [postId]);

  useEffect(() => {
    updateComments();
  }, [updateComments]);

  return (
    <>
      <h3>Comments</h3>
      {comments.length === 0 && <p>No comments have been added so far.</p>}
      {error !== undefined && <p className="error">Failed to load comments.</p>}
      {isLoading && <p className="loader">Loading comments ...</p>}
      <div>
        {comments.map((comment) => (
          <div className="comment" key={comment.id} id={`comment-${comment.id}`}>
            <Meta comment={comment} />
            <div>
              {formatComment(comment.content).map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="print-none">
        <h3>Add comment</h3>
        <CommentForm postId={postId} slug={slug} title={title} updateComments={updateComments} />
      </div>
    </>
  );
};
