import React, { useLayoutEffect, useRef } from 'react';
import { isTouchDevice } from './isTouchDevice.js';
import { LightboxImage } from './LightboxImage.js';
import { LightboxSpinner } from './LightboxSpinner.js';
import { LightboxTitle } from './LightboxTitle.js';
import { LightboxControls } from './LightboxControls.js';

/**
 * Renders the lightbox.
 */
export const LightboxCanvas = () => {
  const lightboxRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    (async () => {
      if (isTouchDevice() && lightboxRef.current && document.fullscreenEnabled) {
        try {
          await lightboxRef.current.requestFullscreen({ navigationUI: 'hide' });
        } catch (err) {
          console.log(`Lightbox: fullscreen request was denied.`);
        }
      }
    })();
  }, []);

  return (
    <div ref={lightboxRef} className="lightbox">
      <LightboxImage />
      <LightboxSpinner />
      <LightboxTitle />
      <LightboxControls />
    </div>
  );
};
