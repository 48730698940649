import { Modify } from '../Modify.js';
import { FunctionCache } from '../cache.js';

const cache = new FunctionCache();

/**
 * Creates modification operation for the given object property.
 * Returns stable function identity.
 */
export const createModifyProperty = <T extends object, K extends keyof T>(
  op: Modify<T[K]>,
  key: K
): Modify<T> => cache.cached(op, key, () => (value: T) => ({ ...value, [key]: op(value[key]) }));
