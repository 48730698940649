import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

/**
 * Renders the buttons row in forms.
 */
export const Buttons = ({ children }: Props) => {
  return <div style={{ display: 'flex', gap: '0.5rem' }}>{children}</div>;
};
