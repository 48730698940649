/**
 * Covers both property keys and number indexes for
 * "update", "modify", and "write" functions.
 */
type Aux = string | number | symbol;

/**
 * Helper class to return stable function identity for
 * "update", "modify", and "write" functions in most cases.
 */
export class FunctionCache {
  private store: WeakMap<object, Map<Aux, unknown>>;
  private cacheHits: number;

  constructor() {
    this.store = new WeakMap<object, Map<Aux, unknown>>();
    this.cacheHits = 0;
  }

  /**
   * Returns the cached value. If the value is not in the cache
   * then it's created using the "create" function.
   */
  cached<V>(fn: object, aux: Aux, create: () => V): V {
    let functionStore = this.store.get(fn);
    if (functionStore) {
      let value = functionStore.get(aux);
      if (value) {
        this.cacheHits += 1;
      } else {
        value = create();
        functionStore.set(aux, value);
      }
      return value as V;
    } else {
      const value = create();
      functionStore = new Map<Aux, unknown>();
      functionStore.set(aux, value);
      this.store.set(fn, functionStore);
      return value;
    }
  }

  /**
   * Returns the number of cache hits.
   */
  getCacheHits() {
    return this.cacheHits;
  }
}

/**
 * Helper class to return stable function identity for
 * arbitrary updates given the operation is pure and
 * has stable identity.
 */
export class OperationCache {
  private store: WeakMap<object, WeakMap<object, unknown>>;
  private cacheHits: number;

  constructor() {
    this.store = new WeakMap<object, WeakMap<object, unknown>>();
    this.cacheHits = 0;
  }

  /**
   * Returns the cached value. If the value is not in the cache
   * then it's created using the "create" function.
   */
  cached<V>(fn: object, op: object, create: () => V): V {
    let functionStore = this.store.get(fn);
    if (functionStore) {
      let value = functionStore.get(op);
      if (value) {
        this.cacheHits += 1;
      } else {
        value = create();
        functionStore.set(op, value);
      }
      return value as V;
    } else {
      const value = create();
      functionStore = new WeakMap<object, unknown>();
      functionStore.set(op, value);
      this.store.set(fn, functionStore);
      return value;
    }
  }

  /**
   * Returns the number of cache hits.
   */
  getCacheHits() {
    return this.cacheHits;
  }
}
