import React, { Fragment, MouseEvent, useCallback } from 'react';
import { useMemo } from 'react';
import { extractTagsInfo } from './extractTagsInfo.js';
import { CloudTag } from './CloudTag.js';
import { useSelectedTag, useSetSelectedTag } from './TagSelection.js';
import { AllPostsEntry } from '../../packages/blog-data/types/AllPostsEntry.js';

type Props = {
  posts: AllPostsEntry[];
};

/**
 * Renders the tag cloud on frontend.
 */
export const TagCloud = ({ posts }: Props) => {
  const selectedTag = useSelectedTag();
  const setSelectedTag = useSetSelectedTag();

  const tagsInfo = useMemo(() => extractTagsInfo(posts), [posts]);

  const handleClickReset = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setSelectedTag(undefined);
    },
    [setSelectedTag]
  );

  return (
    <div>
      {tagsInfo.map((tagInfo, i) => (
        <Fragment key={tagInfo.tag}>
          {i > 0 && <> </>}
          <CloudTag tagInfo={tagInfo} />
        </Fragment>
      ))}
      {selectedTag !== undefined && (
        <>
          <br />
          <br />
          <a href="/all" onClick={handleClickReset}>
            Reset tag selection
          </a>
        </>
      )}
    </div>
  );
};
