import React, { MouseEvent, ReactNode } from 'react';
import { clsx } from 'clsx';

type Props = {
  type?: 'submit' | 'button';
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  size?: 'normal' | 'small' | 'large';
};

/**
 * Renders <button> element.
 */
export const Button = ({ type = 'button', children, onClick, size = 'normal' }: Props) => {
  const className = clsx('button print-none', {
    'small': size === 'small',
    'large': size === 'large'
  });

  return (
    <button type={type} onClick={onClick} className={className}>
      {children}
    </button>
  );
};
