import React from 'react';
import { createRoot } from 'react-dom/client';
import { extractPostData } from '../posts/extractPostData.js';
import { Comments } from './Comments.js';
import { COMMENTS_ELEMENT_ID } from '../../rlaanemets-shared/constants.js';

/**
 * Attempts to render comments.
 */
export const renderComments = () => {
  const commentsElement = document.getElementById(COMMENTS_ELEMENT_ID);
  if (!commentsElement) {
    return;
  }
  const postData = extractPostData();
  if (!postData) {
    return;
  }
  createRoot(commentsElement).render(
    <Comments postId={postData.$id} slug={postData.slug} title={postData.title} />
  );
};
