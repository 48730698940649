import { clsx } from 'clsx';
import React, { memo } from 'react';
import { useId } from '../useId.js';
import { InputWrapper } from './InputWrapper.js';
import { useTargetValue } from './useTargetValue.js';

type Props = {
  label?: string;
  type?: 'text' | 'password' | 'date';
  value?: string;
  name?: string;
  error?: string;
  autoComplete?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  min?: string | number;
  max?: string | number;
};

/**
 * Simple wrapper around text input.
 */
export const Input = memo(function Input({
  label,
  type = 'text',
  value,
  name,
  error,
  onChange,
  placeholder,
  autoComplete,
  readOnly = false,
  defaultValue,
  disabled,
  min,
  max
}: Props) {
  const labelClass = clsx({ 'error': error });
  const inputClass = clsx({ 'error': error });
  const helpClass = clsx('help', { 'error': error });

  const id = useId();
  const handleChange = useTargetValue(onChange);

  return (
    <InputWrapper>
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        type={type}
        className={inputClass}
        value={value}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={handleChange}
        readOnly={readOnly}
        defaultValue={defaultValue}
        id={id}
        disabled={disabled}
        min={min}
        max={max}
      />
      {error && <div className={helpClass}>{error}</div>}
    </InputWrapper>
  );
});
