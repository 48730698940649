import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

/**
 * Wraps different type of input fields.
 */
export const InputWrapper = ({ children }: Props) => {
  return <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>{children}</div>;
};
