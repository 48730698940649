import { useCallback, useEffect, useRef, useState } from 'react';
import { extractHashinfo } from './extractHashinfo.js';

/**
 * Helper hook to extract current hash info for the lightbox.
 */
export const useHashinfo = () => {
  const [info, setInfo] = useState(() => extractHashinfo());
  const intervalRef = useRef<number>();

  const handleHashchange = useCallback(() => {
    setInfo(extractHashinfo());
  }, []);

  useEffect(() => {
    // Sets up a polling times.
    intervalRef.current = setInterval(() => {
      handleHashchange();
    }, 200) as unknown as number;

    return () => {
      // Clears the polling watcher.
      clearInterval(intervalRef.current);
    };
  }, [handleHashchange]);

  return info;
};
