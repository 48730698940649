import { extractJSendResponse } from '../jsend/extractJSendResponse.js';

export type HttpVerb = 'GET' | 'POST' | 'PUT' | 'DELETE';

let started = 0;
let startListener: () => void = () => undefined;
let endListener: () => void = () => undefined;
let errorListener: (error: unknown) => void = () => undefined;

/**
 * Sets callback that is called when the AJAX loader
 * icon should be displayed.
 */
export const setStartListener = (fn: () => void) => {
  startListener = fn;
};

/**
 * Sets callback that is called when the AJAX loader
 * icon should be hidden.
 */
export const setEndListener = (fn: () => void) => {
  endListener = fn;
};

/**
 * Sets the callback that is called when an AJAX error occurs.
 */
export const setErrorListener = (fn: (error: unknown) => void) => {
  errorListener = fn;
};

/**
 * Runs fetch request to the given URL. Expects the response to
 * be a JSend response.
 */
export const requestJSON = async <R, T>(url: string, method: HttpVerb, data: T): Promise<R> => {
  return executeFetch(async () => {
    if (method === 'GET') {
      const response = await fetch(url, {
        method,
        headers: {
          'Accept': 'application/json'
        }
      });
      return extractJSendResponse(await response.json());
    } else {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(data)
      });
      return extractJSendResponse(await response.json());
    }
  });
};

/**
 * Executes GET request to fetch JSON response.
 */
export const requestGetJson = async <R>(url: string): Promise<R> =>
  requestJSON<R, undefined>(url, 'GET', undefined);

/**
 * Executes POST request with JSON and returns the returned JSON response.
 */
export const requestPostJson = async <R, T>(url: string, data: T): Promise<R> =>
  requestJSON<R, T>(url, 'POST', data);

/**
 * Executes POST request with JSON and returns the returned JSON response.
 */
export const requestPutJson = async <R, T>(url: string, data: T): Promise<R> =>
  requestJSON<R, T>(url, 'PUT', data);

/**
 * Executes DELETE request to fetch JSON response.
 */
export const requestDeleteJson = async <R>(url: string): Promise<R> =>
  requestJSON<R, undefined>(url, 'DELETE', undefined);

/**
 * Uploads files (FormData) to the given URL. Assumes that
 * the response is a JSend response. The request is always of type POST.
 */
export const uploadFiles = async <T>(url: string, body: FormData): Promise<T> => {
  return executeFetch(async () => {
    const response = await fetch(url, {
      body,
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json'
      }
    });
    return extractJSendResponse(await response.json()) as T;
  });
};

/**
 * Increases the count and calls the start callback
 * when the sequence of AJAX calls begins.
 */
const startFetch = () => {
  started++;
  if (started === 1) {
    startListener();
  }
};

/**
 * Decreases the count and calls the end callback
 * when the sequence of AJAX calls ends.
 */
const endFetch = () => {
  started--;
  if (started === 0) {
    endListener();
  }
};

/**
 * Helper to execute fetch queries.
 */
const executeFetch = async <T>(factory: () => Promise<T>) => {
  startFetch();
  try {
    return await factory();
  } catch (err) {
    errorListener(err);
    throw err;
  } finally {
    endFetch();
  }
};
