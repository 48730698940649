import React, { ReactNode } from 'react';
import { createGridTemplateColumns } from '../css/createGridTemplateColumns.js';

type Props = {
  columns: number;
  children: ReactNode;
};

/**
 * Renders form elements in the flex container.
 */
export const FormRow = ({ children, columns }: Props) => {
  if (columns === 1) {
    return <div>{children}</div>;
  } else {
    return (
      <div style={{ display: 'grid', gridTemplateColumns: createGridTemplateColumns(columns), gap: '1rem' }}>
        {children}
      </div>
    );
  }
};
