import { generateId } from './generateId.js';

/**
 * Reads the previous user ID or generates a new one.
 */
export const getUserId = () => {
  const match = document.cookie.match(/readers_user\s*=\s*([a-z0-9-]+)/);
  if (match) {
    return match[1];
  }
  const id = generateId();
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);
  document.cookie = 'readers_user=' + id + '; path=/; expires=' + expires.toUTCString();
  return id;
};
