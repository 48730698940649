import React from 'react';
import { FilteredPosts } from './FilteredPosts.js';
import { TagCloud } from './TagCloud.js';
import { TagSelectionProvider } from './TagSelection.js';
import { AllPostsEntry } from '../../packages/blog-data/types/AllPostsEntry.js';

type Props = {
  posts: AllPostsEntry[];
};

/**
 * Renders the all posts page.
 */
export const Posts = ({ posts }: Props) => {
  return (
    <TagSelectionProvider>
      <TagCloud posts={posts} />
      <FilteredPosts posts={posts} />
    </TagSelectionProvider>
  );
};
