import React, { useCallback, useEffect } from 'react';
import { Photo } from './Photo.js';
import { LightboxCanvas } from './LightboxCanvas.js';
import { useHashinfo } from './useHashinfo.js';
import { LightboxProvider } from './LightboxProvider.js';

type Props = {
  photos: Photo[];
  postTitle: string;
};

/**
 * Renders photos lightbox.
 */
export const Lightbox = ({ photos, postTitle }: Props) => {
  const hash = useHashinfo();

  const handleClose = useCallback(() => {
    // Removes hash "#lightbox" from the current URL
    // causing lightbox to close.
    history.pushState(null, '', window.location.pathname);
  }, []);

  useEffect(() => {
    if (hash.lightboxVisible) {
      // Disables vertical scrollbar on body.
      document.body.classList.add('overflow-y-hidden');
    } else {
      // Re-enables the body scrollbar.
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [hash.lightboxVisible]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!hash.lightboxVisible) {
    return null;
  }

  return (
    <LightboxProvider
      photos={photos}
      initialIndex={hash.selectedIndex}
      onClose={handleClose}
      postTitle={postTitle}
    >
      <LightboxCanvas />
    </LightboxProvider>
  );
};
