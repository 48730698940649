import React from 'react';
import { Author } from './Author.js';
import { CommentPublicData } from '../../packages/comments/CommentPublicData.js';

type Props = {
  comment: CommentPublicData;
};

/**
 * Renders one comment meta section.
 */
export const Meta = ({ comment }: Props) => {
  const dateTimeString = new Date(comment.timestamp * 1000).toLocaleString();
  return (
    <div className="meta">
      On {dateTimeString} by <Author comment={comment} />:
    </div>
  );
};
