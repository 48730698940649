/**
 * Helper function to check whether the errors object has an error.
 * Please see the tests for the actual specification.
 */
export const hasError = (errors: object): boolean => {
  if (Array.isArray(errors)) {
    return errors.some((item) => hasError(item));
  } else if (typeof errors === 'object' && errors !== null) {
    return Object.values(errors).some((value) => typeof value === 'string' || hasError(value));
  }
  return false;
};
