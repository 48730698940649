import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Photo } from './Photo.js';

type LightboxState = {
  /**
   * Currently displayed photo.
   */
  photo: Photo;
  /**
   * Index of the currently displayed photo.
   */
  selectedIndex: number;
  /**
   * The entry title.
   */
  postTitle: string;
  /**
   * Boolean flag whether the photo is being loaded.
   */
  isLoading: boolean;
  /**
   * Callback to load the previous photo.
   */
  goToPrevious: () => void;
  /**
   * Callback to load the next photo.
   */
  goToNext: () => void;
  /**
   * Callback to mark that the current photo has been loaded.
   */
  markLoaded: (isComplete: boolean) => void;
  /**
   * Callback to close the lightbox.
   */
  handleClose: () => void;
};

const LightboxContext = createContext<LightboxState | undefined>(undefined);

type Props = {
  photos: Photo[];
  initialIndex: number;
  postTitle: string;
  children: ReactNode;
  onClose: () => void;
};

/**
 * Helper to manage and thread lightbox state through the component tree.
 */
export const LightboxProvider = ({ photos, initialIndex, postTitle, children, onClose }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [isLoading, setLoading] = useState(true);

  const goToPrevious = useCallback(() => {
    console.log(`Lightbox: going to previous photo.`);
    setLoading(true);
    setSelectedIndex((current) => (current <= 0 ? photos.length - 1 : current - 1));
  }, [photos]);

  const goToNext = useCallback(() => {
    console.log(`Lightbox: going to next photo.`);
    setLoading(true);
    setSelectedIndex((current) => (current >= photos.length - 1 ? 0 : current + 1));
  }, [photos]);

  const markLoaded = useCallback((isComplete: boolean) => {
    if (isComplete) {
      setLoading(false);
    }
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  /**
   * Handles global key presses.
   */
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
        goToNext();
      } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
        goToPrevious();
      }
    },
    [goToNext, goToPrevious]
  );

  // Sets the selected index when the initial value changes.
  useEffect(() => {
    setSelectedIndex(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const photo = photos[selectedIndex];

  const value = {
    photo,
    selectedIndex,
    postTitle,
    isLoading,
    goToPrevious,
    goToNext,
    markLoaded,
    handleClose
  };

  return <LightboxContext.Provider value={value}>{children}</LightboxContext.Provider>;
};

/**
 * Returns the current lightbox context.
 */
export const useLightboxContext = () => {
  const context = useContext(LightboxContext);
  if (!context) {
    throw new Error('Lightbox context is not provided.');
  }
  return context;
};
